import '../styles/index.scss';

import {tns} from 'tiny-slider/src/tiny-slider';
import 'simplebar';
import Tabby from 'tabbyjs';
import * as basicLightbox from 'basiclightbox'

/***** INTRO SLIDER *********/

if (document.querySelectorAll('#intro-slider').length) {
  const introSlider = tns({
    container: '#intro-slider',
    mode: "carousel",
    items: 1,
    swipeAngle: false,
    mouseDrag: true,
    autoplay: true,
    autoplayTimeout: 3500,
    autoplayButtonOutput: false,
  });
}

/**** END INTRO SLIDER ******/

/**** OFFERS SLIDER ********/

if (document.querySelectorAll('#offers-slider').length) {
  const offersSlider = tns({
    container: '#offers-slider',
    mode: "carousel",
    items: 1,
    swipeAngle: false,
    mouseDrag: true,
    autoplay: false,
  });
}

/***** END OFFERS SLIDER ******/

/***** GALLERY SLIDER *********/

document.querySelectorAll('[id*="gallery-slider"]').forEach((node) => {
  const gallerySlider = tns({
    container: `#${node.id}`,
    mode: "carousel",
    items: 1,
    responsive: {
      950: {
        items: 3,
        slideBy: 3
      }
    },
    swipeAngle: false,
    mouseDrag: true,
    autoplay: true,
    autoplayTimeout: 3500,
    autoplayButtonOutput: false,
    navPosition: 'bottom',
    slideBy: 1
  });
});

/**** END GALLERY SLIDER ******/

/***** LIGHT BOX *********/

document.querySelectorAll('[data-lightbox]').forEach((node) => {
  node.addEventListener('click', () => {
    const instance = basicLightbox.create(`<img src="${node.getAttribute('data-lightbox-src') || node.src}">`);

    instance.show();
  });
});

/**** END LIGHT BOX ******/

/**** START CONTACT BOX *****/

document.querySelector('[data-contact-box]').addEventListener('click', function(e) {
  const parent = this.parentElement;

  parent.classList.toggle('aside-contact--opened');
});

/**** END CONTACT BOX ******/

/****** ACCORDION *********/

document.querySelectorAll('.accordion__label').forEach((node) => {
  node.addEventListener('click', function(e) {
    e.preventDefault();

    const parent = this.parentElement;

    // If the content is already expanded, collapse it and quit
    if (parent.classList.contains('accordion--active')) {
      parent.querySelector('.accordion__content-wrapper').style.height = 0 + "px";
      parent.classList.remove('accordion--active');

      return;
    }

    // Get all open accordion content, loop through it, and close it
    var accordions = document.querySelectorAll('.accordion.accordion--active');
    for (var i = 0; i < accordions.length; i++) {
      accordions[i].querySelector('.accordion__content-wrapper').style.height = 0 + "px";

      accordions[i].classList.remove('accordion--active');
    }

    // Toggle our content
    parent.querySelector('.accordion__content-wrapper').style.height = parent.querySelector('.accordion__content').clientHeight + "px";
    parent.classList.toggle('accordion--active');
  })
});

// Listen for click on the document
document.addEventListener('click', function (event) {

  //Bail if our clicked element doesn't have the class
  if (!event.target.classList.contains('accordion-toggle')) return;

  // Get the target content
  var content = document.querySelector(event.target.hash);
  if (!content) return;

  // Prevent default link behavior
  event.preventDefault();

  // If the content is already expanded, collapse it and quit
  if (content.classList.contains('active')) {
    content.classList.remove('active');
    return;
  }

  // Get all open accordion content, loop through it, and close it
  var accordions = document.querySelectorAll('.accordion-content.active');
  for (var i = 0; i < accordions.length; i++) {
    accordions[i].classList.remove('active');
  }

  // Toggle our content
  content.classList.toggle('active');
})

/****** END ACCORDION ********/

/***** GALLERY TABS *********/

if (document.querySelectorAll('[data-tabs]').length > 0) {
  var galleryTabs = new Tabby('[data-gallery-tabs][data-tabs]');
}

/**** END GALLERY TABS ******/

/***** FADE IN IMAGES *********/

function showImages(images) {
  const windowHeight = window.innerHeight;

  images.forEach((el) => {
    const topOfWindow = window.scrollY,
      thisPos = el.getBoundingClientRect().top + topOfWindow;

    if (topOfWindow + windowHeight - 200 > thisPos) {
      el.classList.add("fade-photo--show");
    }
  });
}

const fadeImages = document.querySelectorAll('.fade-photo');

window.addEventListener('scroll', () => {
  showImages(fadeImages);
});

showImages(fadeImages);

/**** END FADE IN IMAGES ******/